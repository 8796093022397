<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t("message.write_conclusions") }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button v-if="activeName == 'three'" type="success" @click="print('conclusion/print', model.id)">{{
                $t("message.printing") }}</el-button>
              <!-- <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit">{{$t('message.save_and_close')}}</el-button> -->
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="modal-body editor-content">
          <el-steps :active="active" finish-status="success" simple>
            <el-step :title="$t('message.choose_template')"></el-step>
            <el-step :title="$t('message.write_conclusions')"></el-step>
            <el-step :title="$t('message.printing')"></el-step>
          </el-steps>
          <el-tabs v-model="activeName">
            <el-tab-pane name="first">
              <el-row :gutter="24">
                <el-col :sm="12" v-loading="loadingTemplates">
                  <el-input clearable :placeholder="$t('message.search_template')" v-model="filterText"></el-input>

                  <el-tree class="mt-2 filter-tree" :data="data" node-key="id" @node-click="selected"
                    :filter-node-method="filterNode" ref="tree">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span>
                        {{ data.name }}
                        <span v-if="data.code" style="color: blue">( {{ data.code }} )</span>
                      </span>
                    </span>
                  </el-tree>
                </el-col>
                <el-col :sm="12">
                  <!-- <el-select :disabled="changeTemplateButton" style="width:86%" @change="changeTemplate" clearable v-model="form.form_template_id" placeholder="Выберите шаблонь">
                                        <el-option v-for="(item, index) in templates" :key="index" :label="item.name" :value="item.id"> </el-option>
                  </el-select>-->
                  <el-row class="button_r">
                    <el-button @click="NextTab()" :disabled="nextButton" class="ml-2" style="height: 38px" type="primary">
                      {{ $t("message.next") }}
                      <i class="el-icon-arrow-right el-icon-right"></i>
                    </el-button>
                  </el-row>
                  <el-card class="mt-2" shadow="always" v-if="form.content">
                    <div class="content-table" v-html="form.content"></div>
                  </el-card>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane name="second">
              <el-form ref="form" status-icon :model="form" :rules="rules" class="aticler_m">
                <el-row :gutter="24">
                  <el-col :sm="12">
                    <el-form-item :label="columns.service_id.title" prop="service_id">
                      <el-select clearable v-model="form.service_id" :placeholder="$t(`message.services`)">
                        <el-option v-for="(item, index) in order_model.services" :key="index" :label="item.name"
                          :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-card shadow="always">
                      <ckeditor :editor="editor" v-model="form.content" @ready="onReady" :config="editorConfig">
                      </ckeditor>
                    </el-card>
                  </el-col>
                  <el-col :sm="12">
                    <el-row class="button_r">
                      <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="form.date_time"
                        type="date" placeholder="Pick a day"
                        style="width: 81% !important; display: inline-block"></el-date-picker>
                      <el-button class="mt-4 ml-2" style="height: 38px" type="success" :loading="loadingButton"
                        :disabled="loadingButton ? true : false" @click="submit">
                        {{ $t("message.save_and_go") }}
                        <i class="el-icon-arrow-right el-icon-right"></i>
                      </el-button>
                    </el-row>
                    <el-card class="mt-4" shadow="always">
                      <div class="content-table" v-html="form.content"></div>
                    </el-card>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>
            <el-tab-pane name="three">
              <el-card class="mt-4" shadow="always" style="width: 1000px; margin: 0 auto">
                <div class="content-table" v-html="form.content"></div>
              </el-card>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import print from "@/utils/mixins/print";

export default {
  mixins: [print],
  data() {
    return {
      loadingButton: false,
      active: 0,
      activeName: "first",
      template: "",
      filterText: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      templates: [],
      loadingTemplates: false,
      nextButton: true,
      changeTemplateButton: false,
      selectedTemplate: {},
      form: {
        name: "",
        header_template: "",
        content: "",
        header_template: "",
        hide_header: false,
        hide_footer: false,
        date_time: new Date(),
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    "form.content": {
      handler: function () { },
    },
  },
  created() { },
  async mounted() {
    if (!this.template_list.length) {
      this.loadingTemplates = true;
      this.templateList()
        .then((res) => {
          this.loadingTemplates = false;
        })
        .catch((err) => {
          this.loadingTemplates = false;
        });
    }
    this.categoryDocumentatio();
    // console.log(this.order_model);
    this.form.date_time = new Date();
  },
  computed: {
    ...mapGetters({
      template_list: "formTemplate/list",
      rules: "conclusions/rules",
      model: "conclusions/model",
      order_model: "orders/relation_model",
      columns: "conclusions/columns",
      user_id: "auth/id",
    }),
  },
  methods: {
    ...mapActions({
      templateList: "formTemplate/index",
      categoryDocumentationList: "categoryDocumentation/index",
      save: "conclusions/store",
    }),
    selected(event) {
      if (event.category_id) {
        this.form.form_template_id = event.id;
        this.form.content = event.content;
      } else {
        this.form.form_template_id = null;
        this.form.content = "";
      }
      if (this.form.content != "") this.nextButton = false;
      else this.nextButton = true;
    },
    categoryDocumentatio() {
      this.categoryDocumentationList({ relation: true })
        .then((res) => {
          this.data = res.data.category_forms;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    submit(close = true) {
      this.form.patient_id = this.order_model.patient_id;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.$refs["form"].resetFields();
              this.loadingButton = false;
              this.$notify({
                title: 'Успешно',
                type: "success",
                offset: 130,
                message: res.message
              });
              this.activeName = "three";
              this.active++;
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
                title: 'Ошибка',
                type: "error",
                offset: 130,
                message: err.error.message
              });
            });
        }
      });
    },
    // changeTemplate(){

    //     if (this.form.form_template_id) {
    //         let temp = this.templates.filter(item => { return item.id == this.form.form_template_id })
    //         this.selectedTemplate = temp[0];
    //         this.form.content = temp[0] ? temp[0].content : '';
    //         this.nextButton = false;
    //     }
    //     else{
    //         this.nextButton = true;
    //         this.form.content = '';
    //     }
    // },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    // change(id){
    //     // let item = this.template_list.filter( item => { return item.id ==  id} )
    //     // if (item.length) {
    //     //     this.form.form_template_id = item[0].id;
    //     //     this.form.content = item[0].content;
    //     // }
    // },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form = {};
      this.active = 0;
      this.activeName = "first";
      this.templates = [];
      this.selectedTemplate = {};
      this.$emit("c-close", { drawer: "drawerCreate" });
    },
    NextTab() {
      this.activeName = "second";
      this.active++;
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.indexOf(value) !== -1;
      return dd;
    },
  },
};
</script>
<style lang="scss">
.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  height: 600px !important;
}

.documentation_content .el-card.is-always-shadow {
  min-height: 700px;
}

.editor-content .content-table {
  max-height: 636px;
  overflow: scroll;
}

// .modal-body .el-tabs__header.is-top{
//     // margin:0
// }
</style>