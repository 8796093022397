<template>
  <div>
    <div class="modal_header" style="margin: -1rem -1.2rem 0">
      <el-row class="pt-2" :gutter="10">
        <el-col :xs="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple">
            <!-- <div class="title_h"> -->
<!--            <el-button-group>-->
<!--              <el-button class="back_button" type="primary" icon="el-icon-arrow-left">-->
<!--                <router-link to="/doctor-cabinet/patients">{{-->
<!--                  $t("message.go_back")-->
<!--                }}</router-link>-->
<!--              </el-button>-->
<!--            </el-button-group>-->
            <!-- Пациента:  <b style="text-transform: uppercase;">{{ model.patient ? model.patient.first_name + ' ' + model.patient.surname : '' }}</b> -->
            <!-- </div> -->
          </div>
        </el-col>
        <el-col :xs="24" :md="8" :lg="8" :xl="8" class="text-center">
          {{ $t("message.patient_0") }}:
          <b style="text-transform: uppercase">{{
            model.patient
            ? model.patient.first_name + " " + model.patient.surname
            : " - "
          }}</b>
        </el-col>
        <el-col :xs="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button v-if="model.laboratory_conculations" @click="dialogTableVisible = true">{{ $t("message.back")
              }}</el-button>
<!--              <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false"-->
<!--                @click="submit">{{ $t("message.save") }}</el-button>-->
              <!-- <el-button @click="resetForm()">{{$t('message.close')}}</el-button> -->
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="grid-content">
      <div>
        <el-tabs v-model="activeName">
          <!-- show -->
          <el-tab-pane :label="$t('message.patient_info')" name="first">
            <div v-loading="loadingData">
              <el-card class="box-card mt-2">

                <el-collapse class="open_cliet_hstori mb-4" v-model="activeCollapse">
                  <el-collapse-item :title="$t('message.patient_info')" name="1">
                    <el-row>
                      <el-col :span="5">
                        {{ $t("message.fio") }}:
                        <b>{{
                          model.patient
                          ? model.patient.first_name +
                          " " +
                          model.patient.surname +
                          " " +
                          (model.patient.patronymic
                            ? model.patient.patronymic
                            : "")
                          : ""
                        }}</b>
                      </el-col>
                      <el-col :span="5">
                        {{ $t("message.phone") }}:
                        <b>{{ model.patient ? model.patient.phone : "-" }}</b>
                      </el-col>
                      <el-col :span="5">
                        {{ $t("message.dob") }}:
                        <b>{{
                          model.patient ? model.patient.born_date : "-"
                        }}</b>
                      </el-col>
                      <el-col :span="5">
                        {{ $t("message.gender") }}:
                        <b>{{ model.patient ? model.patient.gender : "-" }}</b>
                      </el-col>
                      <el-col :span="4">
                        {{ $t("message.code") }}:
                        <b>{{ model.patient ? model.patient.code : "-" }}</b>
                      </el-col>

                    </el-row>
                  </el-collapse-item>
                </el-collapse>

                <el-collapse class="open_cliet_hstori mb-4" v-model="activeCollapse3">
                  <el-collapse-item :title="$t('message.order')" name="1">
                    <el-row>
                      <el-col :span="4">
                        №:
                        <b>{{
                          model.id
                        }}</b>
                      </el-col>
                      <el-col :span="4">
                        {{ $t("message.clinic_diagnos") }}:
                        <b>{{ model.diagnos }}</b>
                      </el-col>
                      <el-col :span="4">
                        {{ $t("message.partner_clinic") }}:
                        <b>{{
                          model.partner_clinic ? model.partner_clinic.name : "-"
                        }}</b>
                      </el-col>
                      <el-col :span="4">
                        {{ $t("message.partner_doctor") }}:
                        <b>{{
                          model.partner_doctor ? model.partner_doctor.name : "-"
                        }}</b>
                      </el-col>
                      <el-col :span="4">
                        {{ $t("message.department") }}:
                        <b>{{ model.department ? model.department.name : "-" }}</b>
                      </el-col>
                      <el-col :span="4">
                        {{ $t("message.material") }}:
                        <b>{{ model.material ? model.material.name : "-" }}</b>
                      </el-col>

                    </el-row>
                  </el-collapse-item>
                </el-collapse>

                <el-collapse class="open_cliet_hstori pt-0 mb-4" v-model="activeCollapse2">
                  <el-collapse-item :title="$t('message.order_service')" name="1">
                    <ul class="ul_usluga">
                      <li v-for="(item, i) in model.services" :key="i">
                        <div>-{{ item.name }}</div>
                        <!-- <el-button
                          v-can="'currencies.create'"
                          @click="createPatientConclusion(item.id)"
                          type="success"
                          icon="el-icon-edit"
                          size="small"
                          round
                          >{{ $t("message.conclusions") }}</el-button
                        > -->
                      </li>
                    </ul>
                  </el-collapse-item>
                </el-collapse>
              </el-card>
            </div>
          </el-tab-pane>
          <!-- end show -->
          <!--********************************* end tab  *********************************-->

          <!-- my conclusion -->
          <!-- <el-tab-pane :label="$t('message.my_conclusions')" name="second">
            <PatientDoctorConclusion></PatientDoctorConclusion>
          </el-tab-pane> -->
          <!--********************************* end tab  *********************************-->
          <!-- end my conclusion -->


          <!-- Hamma zaklucheniyalar -->
          <el-tab-pane :label="$t('message.all_conclusions')" name="third">
            <PatientAllConclusion></PatientAllConclusion>
          </el-tab-pane>

          <!--Laboratoriya zaklucheniyasi -->
          <!-- <el-tab-pane
            :label="$t('message.all_laboratory_conclusions')"
            name="fourth"
          >
            <PatientLaboratoryConclusion></PatientLaboratoryConclusion>
          </el-tab-pane> -->
          <!--********************************* end tab  *********************************-->
        </el-tabs>
      </div>
    </div>

    <el-dialog :title="$t('message.back')" :visible.sync="dialogTableVisible" width="30%">
      <h5>{{ $t("message.reason") }}</h5>
      <el-input type="textarea" :rows="2" v-model="form.reason" />
      <span slot="footer" class="dialog-footer text-center w-100 d-block">
        <el-button @click="dialogTableVisible = false">{{
          $t("message.cancel")
        }}</el-button>
        <el-button type="primary" style="width: 200px" @click="back()">{{
          $t("message.yes")
        }}</el-button>
      </span>
    </el-dialog>

    <el-drawer title="Новый заключение" :visible.sync="drawerCreate" size="100%" :wrapperClosable="false"
      :drawer="drawerCreate" @opened="afterOpened()" @closed="afterClose()">
      <div>
        <crm-create :serviceId="changeServiceId" @c-close="closeDrawer('drawerCreate')" ref="createDrawer"></crm-create>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PatientAllConclusion from "./Parts/PatientAllConclusion";
// import PatientLaboratoryConclusion from "./Parts/PatientLaboratoryConclusion";
import createConclusion from "./createConclusion";
// import PatientDoctorConclusion from "./Parts/PatientDoctorConclusion";
import CrmCreate from "./createConclusion";
import list from "@/utils/mixins/list";

export default {
  // mixins:[list],
  components: {
    createConclusion,
    // PatientDoctorConclusion,
    CrmCreate,
    PatientAllConclusion,
    // PatientLaboratoryConclusion,
  },
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  data() {
    return {
      drawerCreate: false,
      id: null,
      drawerShow: false,
      activeCollapse: "1",
      activeCollapse2: "1",

      activeCollapse3: "1",
      activeName: "first",
      loadingButton: false,
      loadingData: false,
      form: {},
      tableOrderData: [],
      tablePatientData: [],
      dialogTableVisible: false,
      tableServicesData: [],
      reopenShow: false,
      changeServiceId: null,
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.id = this.$route.params.id;
        this.fetchData();
      },
      immediate: true,
    },
  },
  async created() {
    if (!this.id) {
      this.$router.push("/doctor-cabinet/patients");
    }
    if (this.condition_list.length == 0) {
      this.conditions();
    }
  },
  async mounted() {
    this.form = JSON.parse(JSON.stringify(this.model));
  },
  computed: {
    ...mapGetters({
      rules: "orders/rules",
      model: "orders/relation_model",
      columns: "orders/columns",
      condition_list: "conditions/list",
    }),
  },
  methods: {
    ...mapActions({
      conditions: "conditions/iventory",
      save: "orders/updateStatus",
      showModel: "orders/relationShow",
      backTo: "orders/back",
      conclusionList: "conclusions/index",
    }),
    submit() {
      if (!this.form.condition_id) {
        this.activeName = "first";
        // this.$refs.condition_id.focus()
        this.$nextTick(() => {
          this.$refs.condition_id.focus();
        });
        return;
      }
      this.loadingButton = true;
      const query = { id: this.model.id, condition_id: this.form.condition_id };
      this.save(query)
        .then((res) => {
          this.loadingButton = false;
          // this.$router.go(-1);
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
        })
        .catch((err) => {
          this.$notify({
            title: 'Ошибка',
            type: "error",
            offset: 130,
            message: err.error.message
          });
          this.loadingButton = false;
        });
    },
    back(close = true) {
      this.dialogTableVisible = false;
      const query = { id: this.model.id, reason_for_return: this.form.reason };
      this.backTo(query)
        .then((res) => {
          this.$router.push(`/doctor-cabinet/patients`);
          this.$emit("c-close", { drawer: "drawerUpdate" });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Операция отменена",
          });
        });
    },
    fetchData() {
      this.loadingData = true;
      const query = { id: this.id };
      this.showModel(query)
        .then((res) => {
          this.loadingData = false;
          // this.fetchDataConclusions()
        })
        .catch((err) => {
          this.router.go(-1);
          this.loadingData = false;
        });
    },
    fetchDataConclusions() {
      const query = { patient_id: this.id };
      this.conclusionList(query)
        .then((res) => { })
        .catch((err) => { });
    },
    createPatientConclusion(service_id) {
      this.changeServiceId = service_id;
      this.drawerCreate = true;
    },
    resetForm() {
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
    closeDrawer() {
      this.drawerCreate = false;
    },
    afterOpened() {
      this.$refs["createDrawer"].afterOpened();
    },
    afterClose() {
      this.$refs["createDrawer"].afterClosed();
    },
  },
};
</script>
<style>
.back_button a {
  color: #ffffff;
}

.back_button a:hover {
  text-decoration: none;
}
</style>